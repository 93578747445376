// -----------------------------------------------------------------------------------------------
// font faces and sizes
// -----------------------------------------------------------------------------------------------
$font-size-base: 16px;
$font-color: #495057;
$font-face-fallback: arial, sans-serif;
$font-face-regular: 'Roboto Regular', $font-face-fallback;
$font-face-light: 'Roboto Light' , $font-face-fallback;
$font-face-semi-bold: 'Roboto Medium', $font-face-fallback;
$font-face-bold: 'Roboto Bold', $font-face-fallback;
$font-face-primary: $font-face-regular;

// -----------------------------------------------------------------------------------------------
// screen breakpoints
// -----------------------------------------------------------------------------------------------
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
// used to over ride gds breakpoint for medium
$breakpoint-gds-medium: 640px;
$breakpoint-large: 992px;
$breakpoint-x-large: 1200px;
$breakpoint-xx-large: 1320px;

// -----------------------------------------------------------------------------------------------
// pages column gutters
// -----------------------------------------------------------------------------------------------
$gutter-width: 15px;

// -----------------------------------------------------------------------------------------------
// branding colours
// -----------------------------------------------------------------------------------------------
$brand-color-1: #023548;
$brand-color-2: #9d9694;
$brand-color-3: #bccf21;
$brand-color-3-o: rgba($brand-color-3, 0.2);
$brand-color-4: #89c8d2;
$brand-color-5: #53addb;
$brand-color-6: #2f5e80;
$brand-color-7: #dfd5b8;
$brand-color-8: #c4b6ab;
$brand-color-9: #626267;
$brand-color-10: #dddf4b;
$brand-color-11: #94aa5d;
$brand-color-12: #557e42;
$brand-color-13: #b1b4b6;

// -----------------------------------------------------------------------------------------------
// link styling
// -----------------------------------------------------------------------------------------------
$link-color: #1d70b8;
$link-hover-color: #003078;
$link-focus-color: $brand-color-3;

// -----------------------------------------------------------------------------------------------
// input styling
// -----------------------------------------------------------------------------------------------
$input-border-color: #999;
$input-font-color: #495057;
$input-border-radius: 4px;
$input-focus-visible: $brand-color-1;

$checkbox-radio-focus-color:$brand-color-3;
$checkbox-radio-hover-color: white;
$checkbox-radio-focus-width: 4px;

// -----------------------------------------------------------------------------------------------
// errors and warning colours
// -----------------------------------------------------------------------------------------------
$color-error: #d0021b;
$color-error-hover:  #aa2a10;
$color-error-background: #fbecec;
$color-warning: #856404;
$color-warning-background: #fff3cd;
$color-success: #00703c;
$color-success-background: #d4edda;
$color-info: #004085;
$color-info-background: #cce5ff;

// -----------------------------------------------------------------------------------------------
// grey box
// -----------------------------------------------------------------------------------------------
$grey-box-background: #fafafa;
$grey-box-border: #9d9c9c;

$border-radius: 0;
