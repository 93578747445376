.authNavigation {
  width: 65%;
  justify-content: center;

  &__link {
    color: #023548;
    background: white;
    padding: 12px 30px 12px 30px;
    border-radius: 15px;
    outline: none;
    border: 5px solid #023548;
    text-decoration: none;
  }

  &__link:hover,
  &__link:focus {
    color: white;
    background: #023548;
  }
}
