@import '~scss/backOffice/imports';

.actions {
  @include margin(null, null, 50px, null);

  display: flex;
  flex-direction: column;
}

.requestId {
  @include margin(null, null, 50px, null);

  width: 100%;
}

@include screen-size(medium) {
  .actions {
    flex-direction: row;
  }

  .requestId {
    width: 300px;
  }
}
