@import '~scss/public/imports';

.fields {
  display: flex;
  align-items: flex-end;
}

.fieldSearch {
  width: 100%;
  margin-right: 0;
}

.fieldBreed,
.fieldGender,
.fieldAgeRange,
.fieldLocation {
  display: none;
}

@include screen-size(small) {
  .fields {
    > div {
      margin-right: 16px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .fieldSearch {
    width: 300px;
  }

  .fieldBreed {
    display: block;
  }
}

@include screen-size(large) {
  .fieldGender,
  .fieldAgeRange,
  .fieldLocation {
    display: block;
  }
}
