@import '~scss/backOffice/imports';

.formGroup {
  @include form-group;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;

  &.inline {
    flex-direction: row;
  }
}
