@import '~scss/public/imports';

.actions {
  @include margin(20px, null, null, null);

  display: flex;
}

.accept {
  flex-grow: 1;
}

.reject {
  @include margin(null, null, null, 10px);

  flex-grow: 1;
  text-align: right;
}

.hide {
  display: none;
}

.exitBtn {
  margin-left: 10px;
}
