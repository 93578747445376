@import '~scss/public/imports';

.count {
  position: relative;
  width: fit-content;

  &::after {
    @include font-size(12px);

    content: attr(data-count);
    line-height: 12px;
    position: absolute;
    top: 2px;
    right: 3px;
    color: $grey-box-border;
    display: block;
    z-index: 9999;
  }
}

.textbox {
  @include textbox;
}

.textarea {
  @include textbox;

  height: auto;
}

@include screen-size(medium) {
  .maxWidth {
    width: $input-max-width;
  }
}

.fieldDescription {
  color: $brand-color-9;
}

.bo {
  @include padding(6px, 12px, 6px, 12px);
}

.full {
  width: 100%;
}

.three-quarters {
  width: 75%;
}

.half {
  width: 50%;
}

.one-quarter {
  width: 25%;
}
