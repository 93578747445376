@import '~scss/public/imports';

.aboutPanel {
  @include margin(null, null, $gutter-medium, null);
}

.summary {
  @include padding(3px, 0, 3px, 18px);

  position: relative;
  cursor: pointer;
  display: inline-block;
  list-style-type: none;
  color: $link-color;
  text-decoration: underline;

  &::before {
    @include position-absolute(7px, null, null, -1px);
    @include arrow-right($link-color, $link-hover-color, 3px);

    content: '';
    clip-path: none !important;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    background-color: $link-focus-color;
    color: $font-color;
    text-decoration: none;
    box-shadow: 0 4px 0 0 black;

    &::before {
      border-color: black !important;
    }
  }

  &:focus-visible {
    outline: none;
  }
}

.aboutPanel[open] > .summary::before {
  @include arrow-down($link-color, $link-hover-color, 3px);

  top: 5px;
  left: 2px;
}

.content {
  @include margin(25px, null, null, null);
  @include border(6px, solid, #d2d2d2, left);
  @include padding(null, null, null, 10px);

  div,
  p {
    @include margin(null, null, 20px, null);
  }
}

.noSidebar {
  border: none !important;
  padding: 0 !important;
}
