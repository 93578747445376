@import '~scss/public/imports';

.animalLists {
  @include margin(0, 0, 45px, 0);
}

.addAnimals,
.selectionMethods {
  @include margin(20px, 0, 45px, 0);
}

.accordionHeader {
  @include padding(12px, 10px, 12px, 10px);
  @include border(1px, solid, $grey-box-border, top);

  background-color: $grey-box-background;
  font-size: 20px;
  font-weight: bold;
}

.accordionContent {
  @include padding(0 !important, 0, 0, 0 !important);
}

.notAvailable {
  color: $brand-color-13;
}

.modalFooterCount {
  @include padding(0, 10px, 0, 0);

  font-size: 25px;
}
