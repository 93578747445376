@import '~scss/public/imports';

.actions {
  display: flex;
  margin-top: 20px;
}

.submit {
  flex-grow: 1;
}

.exit {
  @include margin(null, null, null, 10px);

  flex-grow: 1;
  text-align: right;
}
