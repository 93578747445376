@import '~scss/public/imports';

.select {
  @include textbox-border;
  @include textbox;
  @include padding(6px, 35px, 6px, 12px);

  background-image: url('~images/svg/icon-chevron-down.svg');
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  appearance: none;
  cursor: pointer;

  @include screen-size(small) {
    max-width: 350px;
  }
}
