@import '~scss/backOffice/imports';

.message {
  @include border(4px, solid, $color-error, left);
  @include padding(15px, 10px, 15px, 10px);
  @include padding(15px, 10px, 15px, 10px);

  color: $color-error;
  background-color: $color-error-background;
  border-radius: $border-radius;

  ul {
    margin-top: -8px;
  }

  li {
    margin-bottom: 8px;
  }
}

.warning {
  @include border(4px, solid, #856404, left);
  @include padding(15px, 10px, 15px, 10px);

  color: $color-warning;
  background-color: $color-warning-background;
}

.error {
  @include margin(15px, 0, 15px, 0);

  display: block;

  @include font-size(15px);
}
