@import '~scss/public/imports';

.navSub {
  ul {
    @include margin(0, 0, 25px, 0);

    background-color: white;
    display: flex;
    z-index: 100;
    flex-direction: column;

    li {
      flex: 1 1 0;
    }

    a {
      @include padding(7px, null, 8px, 5px);
      @include border(1px, solid, $grey-box-border, bottom);

      display: block;
      color: $brand-color-1;
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
        color: $brand-color-1;
      }

      &:focus {
        box-shadow: none;
      }

      &.active {
        background-color: $grey-box-background;
      }
    }
  }
}

@include screen-size(large) {
  .navSub {
    @include margin(-10px, null, 20px, null);
    @include padding(0, 0, null, 5px);

    display: flex;
    color: $grey-box-border;

    ul {
      background-color: transparent;
      flex-direction: row;

      li {
        @include padding(0, 10px, 0, 10px);

        position: relative;
        text-align: center;
        border-bottom: none;

        &:first-of-type::before {
          content: '';
          position: absolute;
          top: 8px;
          left: 0;
          width: 1px;
          height: 14px;
          background-color: #ccc;
        }

        &::after {
          content: '';
          position: absolute;
          top: 8px;
          right: 0;
          width: 1px;
          height: 14px;
          background-color: #ccc;
        }
      }

      a {
        @include padding(4px, 0, 4px, 0);
        @include border(5px, solid, transparent, bottom);

        font-size: 15px;

        &:hover {
          text-decoration: none;
          color: $brand-color-1;
        }

        &.active,
        &:hover,
        &:focus {
          @include border(5px, solid, $brand-color-3, bottom);

          background-color: transparent;
        }
      }
    }
  }
}
