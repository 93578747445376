@import '~scss/public/imports';

.addBatch {
  @include margin($gutter-small, null, null, null);
  @include padding(null, null, 10px, null);

  display: flex;
  flex-direction: column;
}

.totalAnimals,
.batchNumber,
.batchDescription {
  @include margin(null, null, $gutter-small, null);
}

@include screen-size(large) {
  .addBatch {
    @include padding(null, null, 0, null);

    flex-direction: row;
    align-items: flex-end;
  }

  .totalAnimals {
    width: 120px;
  }

  .totalAnimals,
  .batchNumber,
  .batchDescription {
    @include margin(null, $gutter-small, 0, null);
  }

  .batchDescription {
    width: 395px;
  }
}

.summaryRow {
  @include summary-row;
}
