@import '~scss/backOffice/imports';

.label {
  display: flex;
  align-items: center;

  @include padding(null, null, 10px, null);
  @include font-size(15px);

  color: $input-font-color;
}

.notFound {
  color: $color-error;
  font-weight: bold;
}
