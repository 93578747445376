@import '~scss/public/imports';

.actions {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.errorText {
  @include margin(10px, null, null, null);

  display: block;
  color: $color-error;
}

.amend {
  @include margin(null, $gutter-small, null, null);

  flex-grow: 0;
}

.print {
  display: flex;
  flex-direction: row;

  button {
    margin-right: 20px;
  }
}

.cancel {
  @include margin(null, null, null, 10px);

  flex-grow: 1;
  text-align: right;
}

.exit {
  @include margin(null, null, null, 10px);

  text-align: right;
}

@media print {
  * {
    font-size: 11pt !important;
  }

  .print {
    display: none;
  }

  a,
  .amend,
  .cancel,
  .exit {
    display: none !important;
  }

  .tag {
    background-color: none !important;
    font-size: 15px !important;
    padding: 0 !important;
  }
}

.summaryRow {
  @include summary-row;
}
