.date {
  width: 250px;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.inlineWarningOrError {
  display: flex !important;
  width: 100%;

  > duet-date-picker {
    width: 250px;
  }
}
