@import '~scss/backOffice/imports';

.itemsTotal {
  @include font-size(20px);

  display: flex;
  align-items: center;

  .label {
    @include margin(null, null, 5px, null);
  }
}
