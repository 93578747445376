@import '~scss/public/imports';

.notificationsWarning {
  @include margin($gutter-small, null, $gutter-medium, null);

  display: flex;
  align-items: center;
}

.icon {
  @include font-size(25px);
  @include margin(null, 10px, null, null);
}

.text {
  @include font-size(18px);

  font-weight: bold;
}
