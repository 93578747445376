@import '~scss/public/imports';

.header {
  @include margin(null, null, $gutter-small, null);

  flex: 0 1 auto;
  background-color: $brand-color-1;

  .logo {
    @include margin(10px, null, 10px, null);

    width: 155px;
  }

  a.homeLink {
    @include padding(0, null, 0, null);

    display: inline-block;

    &:focus {
      background-color: $brand-color-3;
      box-shadow: none;
    }
  }

  .toggleNav {
    @include position-absolute(15px, 20px, null, null);

    cursor: pointer;
    background-color: transparent;
    background-image: url('~images/svg/icon-toggle-menu.svg');
    border: none;
    width: 25px;
    height: 25px;

    &:focus {
      background-color: transparent;
    }

    &.close {
      background: url('~images/svg/icon-close-menu.svg');
    }
  }

  .userInfo {
    @include border($grey-box-border, 1px, solid, bottom);

    color: $brand-color-1;
    background-color: white;

    span {
      display: inline-block;
    }

    .icon {
      @include margin(null, 5px, null, null);

      font-size: 20px;
    }

    &.open {
      display: block;
    }

    &.closed {
      display: none;
    }

    .option {
      @include text-link($brand-color-1, none, $brand-color-3, underline);
      @include margin(null, null, null, 5px);

      display: inline-flex;
      cursor: pointer;

      &:focus {
        color: black !important;
      }
    }
  }

  .user {
    display: none;

    &:focus {
      background-color: $brand-color-3;
      color: black !important;
    }

    &:focus-visible {
      outline: none;
    }
  }
}

@include screen-size(large) {
  .header {
    @include margin(null, null, 0, null);

    .toggleNav {
      display: none;
    }

    .userInfo {
      background-color: $brand-color-1;
      color: white;
      border-bottom: none;

      &.closed,
      &.open {
        display: block;
      }

      .option {
        @include text-link(white, none, $brand-color-3, none);

        &:focus {
          box-shadow: none;
        }
      }
    }

    .user {
      @include padding(22px, 15px, 22px, 15px);

      cursor: pointer;
      display: inline-flex;
      float: right;
      height: 100%;
      position: relative;
      z-index: 200;
    }

    .userOptions {
      background-color: $brand-color-1;
      position: absolute;
      top: 65px;
      right: 0;
      white-space: nowrap;

      > * {
        @include padding(15px, 15px, 15px, 15px);

        display: block !important;
        margin: 0 !important;

        &:hover {
          color: white !important;
          background-color: $brand-color-6;
        }
      }
    }
  }
}

.headerPrint {
  display: none;
}

@media print {
  .header {
    display: none;
  }

  .headerPrint {
    display: block;
    padding-bottom: 10px;
    border-bottom: 1px solid #999;
    margin: 0 0.5cm 0.5cm 0.5cm;

    .logo {
      width: 155px;
      height: 40px;
    }
  }
}
