@import '~scss/public/imports';

.inline {
  @include margin(null, null, null, 30px);

  span {
    @include margin(null, 10px, null, null);
  }

  address {
    @include margin(null, null, 13px, null);
  }
}

.nonEnglishCph {
  @include margin(null, null, null, 30px);
}
