@import '~scss/backOffice/imports';

.summaryRow {
  @include summary-row;

  &:last-of-type {
    border: none;
  }
}

.noBottomBorder {
  border-bottom: none;
}
