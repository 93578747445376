@import '~scss/public/imports';

.dateRange {
  @include margin(null, null, 20px, null);

  .date {
    width: 100%;
    margin-bottom: 20px;
  }
}

.enter {
  align-self: flex-end;
}

.bo {
  .date {
    width: 300px !important;
  }
}

@include screen-size(small) {
  .dateRange {
    display: flex;
    align-items: flex-end;

    &.inline {
      display: inline-flex;
    }

    .date {
      margin-bottom: 0;
      margin-right: 30px;
      width: 250px;
    }
  }

  .enter {
    align-self: flex-end;
  }
}
