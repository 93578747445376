@import '~scss/public/imports';

.tagReplacement {
  @include margin(25px, null, null, null);
}

.addReplacementTag {
  @include padding(10px, null, 25px, null);

  display: flex;
  flex-direction: column;
}

.input {
  @include margin(null, null, $gutter-small, null);
}

.newTagNumberWrapper {
  .newTagNumber {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}

@include screen-size(small) {
  .addReplacementTag {
    @include padding(null, null, 0, null);

    flex-direction: row;
    align-items: flex-end;

    .input {
      @include margin(null, $gutter-small, 0, null);
    }

    .batchNumber {
      @include margin(null, $gutter-small, 0, null);

      width: 240px;
    }

    .tagNumber {
      @include margin(null, $gutter-small, 0, null);

      width: 130px;
    }
  }
}
