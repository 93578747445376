@import '~scss/backOffice/imports';

.hint {
  display: block;
  margin: 5px 0 10px 0;

  @include font-size(14px);
}

.notFound {
  color: $color-error;
}
