@import '~scss/backOffice/imports';

.notes {
  width: 100%;
}

.itemListHeader {
  @include border(1px, solid, $grey-box-border, bottom);
  @include margin(null, null, 10px, null);

  font-weight: bold;

  td {
    @include padding(10px, 10px, 10px, null);
  }
}

.itemList {
  @include border(1px, solid, $grey-box-border, bottom);
  @include margin(null, null, 5px, null);

  td {
    @include padding(10px, 10px, 10px, null);

    &:last-child {
      margin-left: auto;
      text-align: right;

      button {
        margin-left: auto;
      }
    }
  }

  &:last-of-type {
    @include margin(null, null, 20px, null);

    border: none;
  }
}
