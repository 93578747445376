@import '~scss/backOffice/imports';

.fetching {
  @include margin(10px, null, 20px, null);

  color: $brand-color-8;
  font-weight: normal;
  display: inline-block;
}

.bold {
  font-weight: bold;
}

.inline {
  display: inline-block;
  padding-bottom: 0;
}

.type {
  @include margin(10px, null, 10px, null);
}

.name {
  @include margin(10px, null, 10px, null);

  font-weight: bold;
}

.address {
  @include margin(null, null, 20px, null);

  display: inline-block;
}

.cphInformation {
  background-color: #fafafa;
  border: 1px solid #ccc;
}

.noAddress {
  @include margin(10px, null, 10px, null);

  color: $color-warning;

  .icon {
    @include margin(null, 2px, null, null);
  }
}

.addressLine {
  @include margin(null, null, 6px, null);
}
