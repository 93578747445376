@import '~scss/public/imports';

.holdingListRadios {
  max-height: 60px;
}

.selectHoldingLabel {
  display: flex;
}

.cph {
  display: inline-block;
  min-width: 110px;
  font-weight: bold;
}

.address {
  display: none;

  @include screen-size(small) {
    display: inline;
  }
}

.holdingApproval {
  @include margin(10px, null, null, null);
  @include padding(20px, 20px, 10px, 20px);
  @include border(1px, solid, $grey-box-border);

  background-color: $grey-box-background;
}

.noBottomBorder {
  dt,
  dd {
    border-bottom: none;
  }
}

.confirmHolding {
  display: flex;

  @include margin($gutter-small, null, $gutter-small, null);
}

.yes,
.no {
  @include margin(null, $gutter-small, null, null);
}

.cphSearch {
  @include margin(null, 10px, null, null);
}

.cphSearchError {
  margin-top: 0;
  margin-bottom: 25px !important;
}

.destinationHoldingAddress {
  white-space: nowrap;
  margin: 0 !important;
  padding-left: 2%;
}

.recentlyUsedHoldings {
  @include margin(null, null, $gutter-medium, null);
}

.holdingListRow {
  display: flex;
  width: 100%;
  padding: 0;

  @include border(1px, solid, $grey-box-border, bottom);

  &:last-of-type {
    border: none;
  }
}

.holdingListRowSelected {
  background-color: $brand-color-3-o;

  @include border(1px, solid, $brand-color-3, bottom);
}

.search {
  display: flex;
  align-items: flex-end;

  @include margin(null, null, $gutter-medium, null);
}

.input {
  width: 100%;

  @include margin(null, $gutter-small, null, null);

  @include screen-size(small) {
    width: 400px;
  }
}

.message {
  margin-top: -15px;
  margin-bottom: 20px;
}

.summaryRow {
  @include summary-row;
}

.multiSearch {
  display: flex;
  flex-direction: column;
}

.holdingsDropdown,
.holdingName,
.holdingPostcode {
  @include margin(null, $gutter-small, $gutter-small, null);
}

@include screen-size(medium) {
  .multiSearch {
    flex-direction: row;
    align-items: flex-end;
  }

  .holdingTypeDropdown,
  .holdingName,
  .holdingPostcode {
    @include margin(null, $gutter-small, 0, null);
  }

  .holdingTypeDropdown {
    width: 285px;
  }

  .holdingName {
    width: 350px;
  }
}

.singleSearch {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.cphNumber,
.fsaNumber {
  flex-grow: 1;

  @include margin(null, $gutter-small, null, null);
}

.holdingsDropdown {
  width: 100%;

  @include screen-size(small) {
    > div {
      width: 250px;
    }
  }
}

@include screen-size(medium) {
  .cphNumber,
  .fsaNumber {
    flex-grow: 0;
  }
}
