@import '~scss/public/imports';

.modal {
  position: fixed;
  z-index: 500;
  left: 15%;
  top: 30%;
  box-sizing: border-box;
  width: 95%;
  height: 85%;
  max-width: 1250px;
  max-height: 850px;
  background-color: white;
  -webkit-transition: opacity 0s ease-in;
  -moz-transition: opacity 0s ease-in;
  transition: opacity 0s ease-in;
  opacity: 0;
  pointer-events: none;

  &.open {
    opacity: 1;
    pointer-events: auto;

    &:not(:focus-within) {
      border-color: transparent;
      transition: border-color 0.01s;
    }
  }

  &.notification {
    @include padding(null, null, 65px, null);

    height: auto;
  }
}

.modalHeader {
  @include font-size(18px);
  @include padding(15px, 20px, 15px, 20px);
  @include margin(10px, 10px, 10px, 10px);

  display: flex;
  align-items: center;
  background-color: $brand-color-1;
  font-weight: bold;
  color: white;
  position: relative;

  i {
    @include font-size(25px);

    margin-right: 8px;
  }
}

.modalBody {
  @include padding(null, 20px, 10px, 20px);

  height: calc(100% - 145px);
  overflow-y: auto;
  overflow-x: hidden;

  .tableFilters {
    @include margin(-10px, -10px, 0, -10px);
  }
}

.modalFooter {
  @include padding(15px, 20px, 10px, 20px);

  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid $grey-box-border;
  text-align: right;
  justify-content: space-between;
  min-height: 80px;

  @include screen-size(small) {
    flex-direction: row;
  }

  button {
    margin-top: 1px;
    margin-right: 20px;
  }

  button:last-of-type {
    margin-right: 0;
  }
}

.modalMessage {
  align-items: center;
  display: flex;
  font-weight: bold;
  line-height: 25px;
  text-align: left;

  span {
    font-size: 25px;
    vertical-align: bottom;
  }
}

.modalActions {
  @include padding(null, null, 10px, 20px);

  text-align: right;
  white-space: nowrap;
}

.hiddenInput {
  opacity: 0;
  width: 0;
  height: 0;
  float: left;
}

@media (min-width: 200px) {
  .modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 95vh;
    max-height: none;
  }
}
