@import '~scss/public/imports';

.footer {
  @include margin(30px, null, null, null);
  @include padding(null, null, 20px, null);

  font-size: 16px;
  background-color: $brand-color-1;
  color: white;

  a {
    @include text-link(white, none, $brand-color-3, none); // important used as gov-uk variables are overriding class - need to look into this
    @include padding(3px, null, 3px, null);

    font-size: 16px !important;
    text-decoration: none !important;

    &:focus {
      background-color: $link-focus-color;
      color: black !important;
      text-decoration: none;
      box-shadow: 0 4px 0 0 black;
    }

    &:focus-visible {
      outline: none !important;
    }
  }

  span {
    display: block;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  @include margin(20px, null, 10px, null);

  font-weight: bold;
  color: $brand-color-3;
}

.info {
  @include margin(null, null, 10px, null);
}

.nav ul {
  display: flex;
  flex-direction: column;
}

@include screen-size(small) {
  .content {
    flex-direction: row;
  }
}

@include screen-size(medium) {
  .content {
    width: 75%;
  }
}

@include screen-size(large) {
  .nav ul {
    flex-direction: row;

    li {
      @include margin(null, 15px, null, null);
      @include padding(null, 15px, null, null);

      position: relative;

      &::after {
        @include position-absolute(5px, 0, null, null);

        content: '';
        height: 11px;
        width: 1px;
        background-color: white;
      }

      &:last-of-type {
        margin-right: 0;
        padding-right: 0;

        &::after {
          display: none;
        }
      }
    }
  }
}

@media print {
  .footer {
    display: none;
  }
}
