@import '~scss/public/imports';

.notificationsBanner {
  @include padding(15px, 20px, 15px, 20px);

  font-weight: bold;
  margin-bottom: 45px;

  h2 {
    margin-bottom: 6px;
  }
}

.notificationsLoading {
  @include border(4px, solid, $color-warning, left);

  background-color: $color-warning-background;
}

.notificationsPresent {
  @include border(4px, solid, $color-error, left);

  background-color: $color-error-background;
}

.notificationsNotPresent {
  @include border(4px, solid, $color-success, left);

  background-color: $color-success-background;
}

.notificationCount {
  color: $color-error;
}

.notificationDescription {
  @include margin(15px, null, null, null);

  font-weight: normal;
}

.fetching {
  color: $brand-color-8;
  font-weight: normal;
}

.holdings {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.icon {
  opacity: 0.5;
}

.accordionContent {
  @include padding(25px !important, 0, 0, 0 !important);
}

.holding {
  @include margin(null, null, 20px, null);
  @include padding(null, null, 4px, 10px);

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  border: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: #f8f8f8;
  }

  &:focus {
    div {
      background-color: $link-focus-color;
      color: $font-color;
      text-decoration: none;
      box-shadow: 0 4px 0 0 black;
    }
  }

  &:focus-visible {
    outline: none;
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #ccc;
    bottom: -10px;
    left: 0;
    right: 0;
  }

  &:last-of-type::after {
    display: none;
  }

  &.error {
    .icon {
      opacity: 1;
      fill: $color-error;
    }

    .label {
      color: $color-error;
    }
  }
}

.species {
  @include padding(null, 15px, null, 5px);

  display: flex;
  flex-direction: column;
  text-align: center;
  height: 50px;
}

.speciesName {
  @include font-size(16px);

  margin-bottom: 5px;
  text-align: left;
}

.cphNumber {
  @include margin(null, null, 3px, null);

  font-weight: bold;
  display: block;
}

.notifications {
  color: $color-error;
}

.notificationsWrapper {
  @include padding(null, 5px, null, 15px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
}

.bold {
  font-weight: bold;
  font-size: 16px;
}

.normal {
  color: $font-color;
}

@include screen-size(medium) {
  .holding {
    @include padding(null, null, null, 4px);

    align-items: center;
    flex-direction: row;
    justify-content: left;

    &::before {
      @include arrow-right($link-color, $link-hover-color, 3px);

      border-color: black;
      position: absolute;
      right: 10px;
      content: '';
    }
  }

  .species {
    @include padding(null, 20px, null, null);

    position: relative;
    border-right: 1px solid #ccc;
  }

  .address span,
  .notifications span {
    display: inline;
  }

  .address br {
    display: none;
  }

  .accordionContent {
    @include padding(25px !important, 0, 0, 30px !important);
  }
}

.arrowRight {
  @include arrow-right($font-color, $font-color, 3px);
}
