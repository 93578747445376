@import '~scss/public/imports';

.paginationContent {
  //@include padding(5px, null, null, null);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.count {
  display: none;
  width: 33.3%;

  div {
    min-width: 200px;
  }
}

.pageSize {
  width: 33.3%;
  display: none;
  text-align: right;
}

.arrowRight {
  @include arrow-right($link-color, $link-hover-color, 3px);

  transform: rotate(-45deg) translate(-1px, -1px);
  -webkit-transform: rotate(-45deg) translate(-1px, -1px);
}

.arrowLeft {
  @include arrow-left($link-color, $link-hover-color, 3px);

  transform: rotate(135deg) translate(-2px, -2px);
  -webkit-transform: rotate(135deg) translate(-2px, -2px);
}

.btnPagination {
  @include margin(null, 5px, null, 5px);
  @include padding(0, 3px, 0, 2px);

  border: none;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    background-color: $link-focus-color;
    box-shadow: 0 4px 0 0 black;

    .arrowRight,
    .arrowLeft {
      border-color: black !important;
    }
  }

  &:focus-visible {
    outline: none;
  }

  &:not([disabled]):hover {
    .arrowRight,
    .arrowLeft {
      border-color: $link-focus-color;
    }
  }
}

.pages {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.3%;
}

.loadPending {
  opacity: .5;
  cursor: not-allowed !important;
}

@include screen-size(small) {
  .count,
  .pageSize {
    display: block;
  }
}

.srOnly {
  display: none !important;
}

.label {
  display: inline-block;
}

.select {
  @include margin(null, 8px, null, 8px);
  @include box(44px, 26px);
  @include border(1px, solid, #999);
  @include padding(null, 20px, null, 5px);
  @include font-size(15px);

  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  background-image: url('~images/svg/select-chevron.svg');
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: right;
  text-align: right;

  &.digits1 {
    width: 38px;
  }

  &.digits2 {
    width: 48px;
  }

  &.digits3 {
    width: 58px;
  }

  &.digits4 {
    width: 68px;
  }

  &:last-child {
    @include margin(null, 0, null, null);
  }

  &:focus {
    box-shadow: 0 0 0 4px $brand-color-3;
  }

  &:focus-visible {
    outline: none;
  }

  &::-ms-expand {
    display: none; /* remove default arrow in Internet Explorer 10 and 11 */
  }
}


@media print {
  .paginationContent {
      display: none;
  }
}