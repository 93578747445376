@import '~scss/public/imports';

.boVaDeclaration{
    .boheaderPrint {
        display: none;
        padding-bottom: 10px;
        border-bottom: 1px solid #999;
        margin: 0 0.3cm 0.5cm 0.3cm;
        
    }
}

@media print {
    @page {
        size: auto;
        margin: 0;
      }
    .boVaDeclaration{
        .boTitleSection{
            display: none;
        }
        .boheaderPrint {
            display: block;
            .logo {
                width: 155px;
                height: auto;
                padding-bottom: 10px;
            }
        }
    }  
}