@import '~scss/public/imports';

.vetBlock{
    margin-bottom: 45px;
    .vetTitle{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 15px;
    }
    .vetdesc {
        font-size: 16px;
        margin-bottom: 10px;
    }
    .vetLink {
        font-size: 16px;
        text-decoration: underline;
        cursor: pointer;
        color: #1d70b8;
    }
}