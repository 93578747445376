@import '~scss/backOffice/imports';

.modal {
  position: fixed;
  z-index: 9500;
  left: 50%;
  top: 10vh;
  box-sizing: border-box;
  width: 90%;
  min-height: 200px;
  max-width: 500px;
  max-height: 80vh;
  background-color: white;
  transform: translate(-50%, 0);

  &.open {
    &:not(:focus-within) {
      border-color: transparent;
      transition: border-color 0.001s;
    }
  }

  &.wide {
    max-width: 800px;
  }
}

.modalHeader {
  @include font-size(18px);
  @include padding(10px, 15px, 10px, 15px);
  @include margin(10px, 10px, 10px, 10px);

  background-color: $brand-color-1;
  font-weight: bold;
  color: white;
  position: relative;
}

.modalBody {
  @include margin(null, null, 63px, null);
  @include padding(10px, 20px, 15px, 20px);

  max-height: calc(80vh - 123px);
  overflow-y: auto;
  position: relative;

  p {
    @include margin(null, null, 15px, null);

    &:last-of-type {
      @include margin(null, null, 0, null);
    }
  }

  h2 {
    @include margin(30px, null, 10px, null);

    font-size: 18px !important;
    font-weight: bold;

    &:first-child {
      @include margin(0, null, 10px, null);
    }
  }

  ol,
  ul {
    @include margin(5px, null, 5px, null);
    @include padding(null, null, null, 20px);

    li {
      list-style-type: initial;
      padding: initial;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }
}

.modalFooter {
  @include padding(10px, 10px, 10px, 10px);

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid black;
  text-align: right;
  display: flex;
  justify-content: space-between;
}

.hiddenInput {
  opacity: 0;
  width: 0;
  height: 0;
  float: left;
}
