@import '~scss/backOffice/imports';

.tooltip {
  position: relative;
  display: inline-block;

  .icon {
    @include font-size(17px);

    margin-left: 8px;
    color: #1d70b8;
    cursor: pointer;
  }

  > .content {
    display: none;
    line-height: 20px;

    &.show {
      @include font-size(14px);

      position: absolute;
      width: 220px;
      bottom: calc(100% + 7px);
      left: calc(50% + 3px);
      margin-left: -110px;
      display: block;
      background-color: $brand-color-1;
      padding: 10px;
      color: white;
      font-weight: normal;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        border-width: 10px;
        border-style: solid;
        border-color: $brand-color-1 transparent transparent transparent;
      }
    }
  }
}
