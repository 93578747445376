@import '~scss/public/imports';
.abattoirPrint{
  text-decoration: underline;
  cursor: pointer;
  color: #1d70b8;
}

@media print {
  .abattoirPrint {
      display: none;
  }
}