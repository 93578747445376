@import '~scss/public/imports';

.foodChainInformation {
  @include padding(20px, null, 10px, null);

  .input {
    @include margin(null, null, 30px, null);

    &:last-of-type {
      @include margin(null, null, 0, null);
    }
  }
}

textarea {
  width: 100%;
}
