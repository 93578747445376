@import '~scss/public/imports';

.cph {
  @include margin(null, null, 15px, null);

  display: flex;
  align-items: flex-end;

  > div {
    @include margin(null, null, 0, null);

    > div {
      @include margin(0, null, 0, null);
    }

    &:first-child {
      min-width: 220px;
      width: 220px;
    }
  }
}

.eventTypeAndDate {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  .eventType {
    @include margin(null, 30px, 15px, null);

    width: 220px;
  }

  .date {
    @include margin(null, null, 15px, null);
  }

  .dateRange {
    > div {
      @include margin(0, null, 0, null);

      > div {
        @include margin(null, null, 15px !important, null);
      }
    }
  }
}

.speciesAndMovementType {
  @include margin(null, null, 0, null);

  display: flex;
  flex-wrap: wrap;

  fieldset {
    @include margin(null, 100px, null, null);

    legend {
      font-weight: normal;
    }

    > div:last-child {
      @include margin(null, null, 0 !important, null);
    }
  }
}

.submitButton {
  @include margin(30px, null, null, null);
}

.navigate {
  position: absolute;
  bottom: 10px;
  right: 0;
}
