@import '~scss/public/imports';

.groupLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.groupBadge {
  border-radius: 2em;
  color: #172b4d;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
  text-align: center;
}
