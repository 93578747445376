@import '~scss/public/imports';

.tab {
  @include margin(null, 0, 8px, null);
  @include border(1px, solid, $grey-box-border);

  cursor: pointer;
  background-color: $tab-bg;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  font-family: $font-face-primary;
  line-height: normal;

  &:focus {
    box-shadow: 0 0 0 4px $brand-color-3;
  }

  &:focus-visible {
    outline: none;
  }

  a {
    &:focus {
      box-shadow: 0 0 0 4px $brand-color-3;
    }

    &:focus-visible {
      outline: none;
    }
  }

  span {
    @include padding(10px, 20px, 10px, 20px);

    display: inline-block;
    line-height: normal;
  }

  .navLink {
    @include padding(10px, 20px, 10px, 20px);
    @include font-size(18px);

    color: $font-color;
    text-decoration: none;
    display: block;

    &:focus {
      background-color: inherit;
    }
  }
}

.tabSelected {
  background-color: $brand-color-1;
  color: white;
  cursor: initial;
}

@include screen-size(medium) {
  .tab {
    @include margin(null, 8px, 0, null);
    @include font-size(18px);

    position: relative;
    border-bottom: none;
  }

  .tabSelected {
    color: $font-color;
    background-color: white;
    text-decoration: none;

    &::after {
      @include position-absolute(null, 0, -1px, 0);

      content: '';
      height: 1px;
      background-color: white;
    }
  }
}

.hidden {
  display: none;
}
