@import '~scss/backOffice/imports';

.inBusinessMove {
  margin-bottom: 25px;
}

.locationAndDate,
.animalsReceived {
  display: flex;
  flex-direction: column;

  @include margin(null, null, 30px, null);

  .manualCPH {
    width: 100%;
  }
}

.cph {
  @include margin(null, 0, 20px, null);
}

.pmDate {
  @include margin(null, 0, 20px, null);
}

@include screen-size(large) {
  .locationAndDate {
    flex-direction: row;

    @include margin(null, null, 20px, null);

    .manualCPH {
      width: 425px;

      @include margin(null, 75px, 20px, null);
    }
  }

  .cph,
  .animalsReceived {
    width: 290px;

    @include margin(null, 75px, 20px, null);
  }

  .pmDate {
    @include margin(null, 75px, 20px, null);
  }

  .comment {
    width: 600px;
  }
}

.manualCPH textarea {
  height: 158px;
}

.entryDate {
  display: flex;
  flex-direction: column;
}
