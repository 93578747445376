@import '~scss/backOffice/imports';

.formGroup {
  @include form-group;
}

.half {
  width: 50%;
}

.quarter {
  width: 25%;
}
