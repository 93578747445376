@import '~scss/backOffice/imports';

.permitMove {
  @include margin(30px, null, null, null);
}

.sourceDocument {
  display: flex;
  flex-direction: column;
}

.species {
  @include margin(30px, null, null, null);
}

.documentType {
  @include margin(30px, null, null, null);
}

.documentSource {
  @include margin(30px, null, 30px, null);
}

@include screen-size(large) {
  .sourceDocument {
    flex-direction: row;
  }

  .documentId {
    @include margin(0, 50px, 20px, null);

    width: 30%;
    max-width: 250px;
  }

  .permitMove {
    @include margin(0, 20px, 20px, null);

    width: 20%;
    max-width: 160px;
  }

  .species {
    @include margin(0, 20px, 20px, null);

    width: 20%;
    max-width: 160px;
  }

  .documentType {
    @include margin(0, 20px, 20px, null);

    width: 20%;
    max-width: 160px;
  }

  .documentSource {
    @include margin(0, 20px, 20px, null);

    width: 30%;
    max-width: 160px;
  }
}
