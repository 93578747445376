@import '~scss/public/imports';

.dateRange,
.otherFields {
  @include margin(20px, null, 20px, null);

  display: flex;
  flex-direction: column;

  .date,
  .field {
    @include margin(null, null, 20px, null);

    width: 100%;
  }
}

.movementRef {
  width: 100%;
}

.actions {
  @include margin(null, null, 0, null);

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }
}

@include screen-size(small) {
  .dateRange,
  .otherFields {
    flex-direction: row;

    .date,
    .field {
      @include margin(null, 30px, 0, null);

      width: 250px;
    }
  }

  .movementRef {
    width: 250px;
  }
}
