@import '~scss/public/imports';

.animalActivity {
  @include margin(null, null, 0, null);

  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  fieldset {
    @include margin(null, 30px, 20px, null);

    &:last-child {
      @include margin(null, 0 !important, null, null);
    }

    legend {
      font-weight: normal;
    }

    > div:last-child {
      @include margin(null, null, 0 !important, null);
    }
  }

  .tagNumber {
    @include margin(null, 30px, null, null);

    width: 250px;
  }

  .submitButton {
    @include margin(30px, null, null, null);
  }
}
