@import '~scss/public/imports';

.tagDetails {
  @include margin(30px, null, null, null);

  .summary {
    @include margin(null, null, 30px, null);

    > div {
      line-height: 20px;

      address {
        @include margin(null, null, 0, null);
      }
    }
  }

  table {
    @include margin(0, null, null, null);
  }
}
