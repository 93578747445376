@import '~scss/backOffice/imports';

.legend {
  font-weight: bold;
  display: flex;
  margin: 0 0 20px 0;
}

.disabled {
  opacity: .5;
}

.reducedMargin {
  margin: 0 0 5px 0;
}
