.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 499;
  left: 0;
  top: 0;
  background-color: black;
  opacity: .5;

  &.isConfirm {
    z-index: 9499;
  }
}
