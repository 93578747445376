@import '~scss/public/imports';

.content {
  height: 100%;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.input {
  text-align: left;
}

.output {
  position: relative;
  flex: 1 1 0;
}

.tableContainer {
  @include table-container;

  top: 0;
  bottom: 50px;
}
