@import '~scss/backOffice/imports';

.errorWrapper {
  @include margin(50px, auto, 50px, auto);

  max-width: 500px;
  text-align: center;

  h1 {
    @include margin(null, null, 25px, null);

    font-size: 30px;
    font-weight: bold;
  }

  p {
    @include margin(null, null, 10px, null);

    font-size: 18px;
  }
}
