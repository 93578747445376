@import '~scss/public/imports';

.hrContainer {
  @include padding(null, null, 70px, null);

  position: relative;
}

.actions {
  @include padding(25px, null, null, null);

  float: left;
}
