@import '~scss/public/imports';

.message {
  @include border(4px, solid, $color-error, left);
  @include padding(15px, 10px, 15px, 10px);

  display: block;
  color: $color-error;
  background-color: $color-error-background;
  font-size: 16px;

  p:last-child {
    @include margin(null, null, 0, null);
  }
}

.success {
  color: $color-success;
  background-color: $color-success-background;
  border-color: green;
}

.warning {
  @include border(4px, solid, #856404, left);
  @include padding(15px, 10px, 15px, 10px);

  color: $color-warning;
  background-color: $color-warning-background;
}

.error {
  @include margin(15px, 0, 15px, 0);
}

.inline {
  @include margin(0, 20px , 0, 10px);
  @include padding(11px, null, 11px, null);

  display: inline-block;
}

.inline-bo {
  @include margin(0, 0, 0, 10px);
  @include padding(13px, null, 13px, null);
}

.dateWidth {
  width: 250px;
}
