.modal {
  h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 40px;

    @media only screen and (max-width: 1024px) {
      font-size: 24px;
    }
  }

  p {
    font-size: 18px;
    padding-right: 200px;
    margin-bottom: 20px;

    @media only screen and (max-width: 1024px) {
      padding: 0;
    }
  }

  ul {
    font-size: 18px;
    margin-bottom: 20px;
    list-style: disc;
    margin-left: 20px;
  }

  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);

  &Content {
    background-color: #fefefe;
    margin: 150px auto;
    padding: 40px;
    width: 960px;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 750px;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      height: 100%;
      margin: 0;
      max-height: none;
    }
  }

  &Open {
    display: block;
  }

  &Close {
    color: black;
    font-size: 40px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    padding: 10px;
    right: 10px;
    background: transparent;
    border: none;
    outline: none;
  }

  &CloseIcon {
    height: 20px;
    width: 20px;
    display: block;
    background: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 95.38 95.38'%3E%3Crect x='35.15' y='-7.21' width='25.09' height='109.81' transform='translate(-19.75 47.69) rotate(-45)' fill='%23010101'/%3E%3Crect x='35.15' y='-7.21' width='25.09' height='109.81' transform='translate(47.69 -19.75) rotate(45)' fill='%23010101'/%3E%3C/svg%3E") no-repeat;
  }

  &Close:hover,
  &Close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
    background: #bccf21;
  }
}
