@import '~scss/public/imports';

.dates {
  display: flex;

  .date {
    margin-right: 30px;
  }
}

.movementDates {
  @include border(1px, solid, $grey-box-border);

  padding: 20px;

  h3 {
    margin: -20px -20px 20px -20px;
    padding: 15px 20px;

    @include border(1px, solid, $grey-box-border, bottom);

    background-color: #fafafa;
  }
}

.summaryRow {
  @include summary-row;
}
