@import '~scss/public/imports';

.tabs {
  .tabsList {
    display: flex;
    flex-direction: column;
  }
}

.tabContent {
  @include padding(5px, null, null, null);
}

@include screen-size(medium) {
  .tabs {
    .tabsList {
      display: flex;
      flex-direction: row;

      @include border(1px, solid, $grey-box-border, bottom);
    }
  }

  .tabContent {
    @include padding($gutter-medium, null, null, null);
  }
}
