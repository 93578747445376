@import '~scss/public/imports';

.selectedHolding {
  @include border(1px, solid, $grey-box-border, bottom);
  @include padding(15px, 15px, 15px, 15px);
  @include margin(null, null, $gutter-medium, null);

  @include screen-size(large) {
    @include margin(20px, null, $gutter-medium, null);

    height: 80px;
  }

  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $grey-box-background;
}

.loading {
  @include position-absolute(0, 0, 1px, 10px);
  @include padding(null, null, null, $gutter-small);

  background-color: $grey-box-background;
  display: flex;
  align-items: center;
}

.cph,
.address,
.species {
  @include margin(null, null, 15px, null);
}

.address {
  display: none;

  @include screen-size(medium) {
    display: block;
  }
}

.title {
  font-weight: bold;
  display: block;
}

address {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 55px;
}

.changeHolding {
  margin-bottom: auto;
  max-width: 150px;
}

@include screen-size(medium) {
  .selectedHolding {
    flex-direction: row;

    .changeHolding {
      margin-bottom: auto;
      margin-left: auto;
      margin-top: 5px;
    }
  }

  .cph,
  .address,
  .species {
    @include margin(null, 30px, 0, null);
  }

  .title {
    @include margin(null, null, 5px, null);

    display: block;
  }

  address {
    flex-direction: row;
    white-space: pre-wrap;
  }

  .icon {
    @include margin(null, 20px, null, null);

    position: relative;
    top: auto;
    right: auto;
  }
}

@media print {
  .selectedHolding {
    flex-direction: row;
    position: relative;
    background-color: white;
    padding-top: 0;
    padding-bottom: 0;
  }

  .cph {
    width: 120px;
    margin-left: 55px;
  }

  .address {
    display: block;
    margin-right: 25px;
  }

  .changeHolding {
    display: none;
  }

  .icon {
    left: 0;
    top: -3px;
  }
}
