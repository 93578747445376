@import '~scss/public/imports';

.fetching {
  color: $brand-color-8;
  font-weight: normal;
}

.summaryRow {
  @include summary-row;

  .label {
    width: 400px;
  }
}

.addProductionTypesLink {
  flex-grow: 2;
  text-align: right;
}
