@import '~scss/public/imports';

.tokensPage {
  font-family: $font-face-primary;
}

.tokenRow {
  display: flex;
  margin: 20px;
}

.token {
  width: 500px;
  margin-right: 20px;

  code {
    background-color: #ddd;
    display: block;
    padding: 15px;
    word-wrap: anywhere;

    .header {
      color: $brand-color-1;
    }

    .body {
      color: $brand-color-6;
    }

    .footer {
      color: $brand-color-1;
    }
  }
}

.button {
  width: 220px;
}

.copied {
  color: green;
  font-weight: bold;
}
