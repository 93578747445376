@import '~scss/backOffice/imports';

.verifyCustomerInformation {
  display: flex;
  flex-direction: column;
}

@include screen-size(large) {
  .verifyCustomerInformation {
    flex-direction: row;
  }

  .keeperDetails {
    @include padding(null, null, null, 10px);

    width: 50%;
  }

  .holdingDetails {
    @include padding(null, 10px, null, null);

    width: 50%;
  }
}
