@import '~scss/public/imports';

.transportInformation {
  @include padding(25px, 20px, 25px, 20px);
  @include margin(null, null, 25px, null);
  @include border(1px, solid, $grey-box-border, bottom);

  display: flex;
  background-color: $grey-box-background;
}

.numberMoved {
  @include margin(null, 10px, null, null);

  font-size: 30px;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}
