@import '~scss/public/imports';

.actions {
  display: flex;
  margin-top: 20px;
}

.submit {
  flex-grow: 1;
}

.exit {
  @include margin(null, null, null, 10px);

  flex-grow: 1;
  text-align: right;
}

@include screen-size(medium) {
  .addIndividualRow {
    @include padding(10px, null, 0, null);

    flex-direction: row;
    align-items: flex-end;
  }

  .batchNumber {
    @include margin(null, $gutter-small, 0, null);

    width: 240px;
  }

  .tagNumber {
    @include margin(null, $gutter-small, 0, null);

    width: 130px;
  }
}
