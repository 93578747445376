@import '~scss/backOffice/imports';

.summaryRow {
  @include summary-row;
}

.dates {
  @include margin(15px, null, null, null);

  display: flex;
  flex-direction: column;

  @include screen-size(small) {
    @include margin(0, null, null, null);
  }

  @include screen-size(medium) {
    flex-direction: row;
  }
}

.date {
  @include margin(null, null, 25px, null);

  display: inline-block;

  @include screen-size(medium) {
    @include margin(null, 50px, 0, null);
  }

  address {
    display: block;
  }

  span {
    display: block;
  }
}

.title {
  @include margin(null, null, 15px, null);

  font-weight: bold;
}
