@import '~scss/public/imports';

.animalSummary {
  @include padding(10px, null, 10px, null);

  display: flex;
}

.title {
  font-weight: bold;
}

.actions {
  margin-left: auto;
}

.animals {
  @include margin(null, null, $gutter-small, null);
  @include padding(10px, null, 10px, null);
  @include border(1px, solid, $grey-box-border, bottom);
}

.tagList {
  padding: 0 !important;
  border-left: none !important;
}

.details {
  @include details;
}
