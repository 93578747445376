@import '~scss/public/imports';

.searchButton {
  @include margin(null, null, 30px, null);
}

@include screen-size(small) {
  .filters {
    .requestTypeAndSearch {
      display: flex;
      align-items: flex-end;
    }

    div {
      margin-bottom: 0 !important;

      &.requestType {
        @include margin(null, 30px, null, null);

        max-width: 250px;
      }
    }
  }
}

@include screen-size(large) {
  .filters {
    display: flex;
    align-items: flex-end;
  }
}
