.welcomeBanner {
  background-color: #023548;

  &__container {
    width: 100%;
    margin: 0 auto;
    padding: 25px 12px 50px 12px;
    box-sizing: border-box;
  }

  &__title {
    font-size: 48px;
    font-size: 3rem;
    padding: 0;
    margin: 0;
    line-height: 1.04167;
    margin-bottom: 10px;
    color: white;
    font-weight: bold;
  }

  &__intro {
    font-size: 22px;
    line-height: 1.3;
    color: white;
  }

  &__content {
    font-size: 18px;
  }

  @media only screen and (max-width: 1024px) {
    padding: 10px;

    &__container {
      padding: 0 0 25px 0;
      width: 100%;
    }

    &__title {
      font-size: 30px;
    }
  }

  @media only screen and (min-width: 1400px) {
    &__container {
      width: 1320px;
    }
  }
}
