@import '~scss/backOffice/imports';

.logo {
  width: 114px;
  height: 30px;
  margin: 10px 14px;
  background-image: url('~images/svg/logo-livestock.svg');
  background-repeat: no-repeat;

  @include screen-size(small) {
    width: 125px;
    height: 33.3px;
    margin: 10px;
  }
}

.toggleMenu {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: -50px;
  z-index: 1000;

  .bar {
    width: 30px;
    height: 2px;
    margin-bottom: 7px;
    background-color: $brand-color-3;
    border-radius: $border-radius;

    @include screen-size(small) {
      display: none;
    }
  }

  &:hover .bar {
    background-color: white;

    @include screen-size(small) {
      background-color: $brand-color-1;
    }
  }
}

.navMenu ul {
  @include margin(20px, 0, 0, 0);

  padding: 0;

  @include screen-size(small) {
    @include margin(50px, 0, 0, 0);
  }

  a {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    padding: 10px 15px;

    @include font-size(15px);

    &:hover {
      color: $brand-color-3;
      text-decoration: none;
    }

    &.active {
      background-color: transparentize(white, 0.9);
    }

    &:focus {
      box-shadow: inset 0 0 0 4px $brand-color-3;
    }

    &:focus-visible {
      outline: none;
    }

    &:not(.active):focus {
      background-color: transparent !important;
    }
  }

  i {
    min-width: 30px;
    font-size: 20px;
  }

  span {
    padding-left: 10px;
  }

  .change_password,
  .logout {
    @include screen-size(small) {
      display: none;
    }
  }
}

.navBar {
  @include position-absolute(0, 0, 0, 0);

  background-color: $brand-color-1;
  width: 100%;
  z-index: 1000;

  &.closed {
    width: 50px;
    margin-left: -50px;

    .logo {
      background-image: url('~images/backOffice/icon-livestock.svg');
      background-position: 2px;
    }

    span {
      display: none;
    }
  }
}

.closeIcon {
  @include position-absolute(15px, 20px, null, null);
  @include font-size(25px);

  color: white;
  cursor: pointer;

  &:hover {
    color: $brand-color-3;
  }
}

@include screen-size(small) {
  .navBar {
    position: relative;
    background-color: $brand-color-1;
    width: 205px;

    &.closed {
      width: 50px;
      margin-left: 0;
    }
  }

  .closeIcon {
    display: none;
  }
}

@media print {
  .navBar {
    display: none;
  }
}