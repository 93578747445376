@import '~scss/backOffice/imports';

.btn {
  @include box(null, 40px);
  @include margin(null, null, null, null);
  @include padding(null, 15px, null, 15px);
  @include font-size(16px);

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: auto;

  &:focus {
    box-shadow: 0 0 0 4px $brand-color-3;
  }

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .extraContent {
    @include margin(null, null, null, 5px);
  }

  .icon {
    margin-right: 5px;

    @include font-size(17px);
  }
}

.primary {
  @extend .btn;

  color: $brand-color-1;
  background-color: $brand-color-3;

  &:hover {
    background-color: $brand-color-10;
  }

  &:focus {
    box-shadow: 0 0 0 4px $brand-color-1;
  }
}

.secondary {
  @extend .btn;

  color: white;
  background-color: $brand-color-1;

  &:hover {
    background-color: $brand-color-6;
  }
}

.tertiary {
  @extend .btn;

  color: $brand-color-1;
  background-color: white;

  @include border(1px, solid, $brand-color-1);

  &:hover {
    background-color: #f8f8f8;
  }
}

.calendar {
  @extend .secondary;

  @include padding(4px, 10px, 0, 10px);

  svg {
    @include box(28px, 33px);
    @include margin(-5px, null, null, null);

    fill: white;
  }
}

.danger {
  @extend .btn;

  color: white;
  background-color: $color-error;

  &:hover {
    background-color: $color-error-hover;
  }
}

.link {
  @extend .btn;

  box-shadow: none;
  background-color: transparent;
  border: none;
  height: auto;
  color: $link-color;
  text-decoration: none;
  padding: 0;

  &:hover {
    color: $link-hover-color;
  }

  &:focus {
    color: $font-color;
  }

  &:disabled {
    background-color: transparent;
    border-color: transparent;
  }
}

.linkDanger {
  @extend .link;

  color: $color-error;

  &:hover {
    color: $color-error-hover;
  }
}

.upload {
  @extend .tertiary;

  @include margin(null, 10px, null, null);

  display: inline-block;
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right-width: 1px;
  background-color: #f8f8f8;
  border-right-color: $input-border-color;

  &:hover {
    background-color: white;
  }
}

.downloadReport {
  @extend .primary;

  float: right;
  display: flex;
  align-items: center;

  span {
    margin: 5px;
  }
}
