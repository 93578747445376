@import '~scss/public/imports';

.content {
  height: 100%;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;

  p {
    @include margin(null, null, 10px, null);
  }
}

.input {
  text-align: left;
}

.output {
  position: relative;
  flex: 1 1 0;
}

.tagNumber {
  width: 200px;
}

.action {
  display: table-cell !important;

  &::before {
    display: none;
  }
}

.tableContainer {
  @include table-container;

  @include screen-size(small) {
    bottom: 50px;
  }
  top: 5px;
}

.uploadInPage{
  .tableContainer{
    position: relative;
    overflow-y: scroll;
  }
}

