.authPanel {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  width: 100%;
  padding: 20px 10px;
}

header {
  flex: 0 1 auto;
  background-color: #023548;
  margin-bottom: 0;

  img {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 155px;
  }
}

.content {
  padding: 40px 0;
  display: flex;

  .heading {
    font-size: 24px;
    margin-bottom: 25px;
    font-weight: bold;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 25px;
  }
  .bodyfirst {
    margin-bottom: 25px;
  }

  .body {
    margin-bottom: 50px;
  }

  .topBody {
    margin-bottom: 20px;
  }

  .buttonLanding {
    margin-bottom: 100px;
  }



  div {
    margin: 25px 0;
    padding: 20px 0;
  }

  div:first-child {
    width: 65%;
  }

  div:last-child {
    width: 35%;
  }

  .linkGroup {
    margin-bottom: 50px;
  }

  p {
    width: 100%;
    padding-right: 90px;
  }

  img {
    width: 50%;
    padding-left: 20px;
  }

  .landingLink {
    display: block;
    margin-bottom: 25px;
    color: #0b0c0c;

    &:hover,
    &:visited,
    &:active {
      color: #0b0c0c;
    }
  }
}

.lowerContent {
  margin-top: 50px;

  h3 {
    font-weight: bold;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .authPanel {
    width: 100%;
    justify-content: center;
  }

  .content {
    padding: 25px 0;
    flex-direction: column;

    div {
      width: 100%;
      margin: 0;

      p {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
      }

      img {
        width: 100%;
        padding: 0;
      }
    }

    div:first-child,
    div:last-child {
      width: 100%;
    }

    .heading {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .authPanel {
    width: 1320px;
  }
}
