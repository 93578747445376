@import '~scss/public/imports';

.manualTagEntry {
  @include padding(10px, null, 10px, null);

  button,
  div,
  textarea {
    margin-bottom: 10px;
  }
}
.notes{
  @include margin(-15px, 0, 0px, 0);
}
