@import 'functions';

// ---------------------------------------------------------------------------------------------
// calculate font sizes - convert size to rem and have pixels as fallback
// ---------------------------------------------------------------------------------------------
@mixin font-size($size) {
  font-size: $size;
  font-size: calculate-rem($size);
}

// ---------------------------------------------------------------------------------------------
// media queries and breakpoints
// ---------------------------------------------------------------------------------------------
@mixin screen-size($breakpoint) {
  @if $breakpoint=='small' {
    @media only screen and (min-width: $breakpoint-small) {
      @content;
    }
  }

  @else if $breakpoint=='medium' {
    @media only screen and (min-width: $breakpoint-medium) {
      @content;
    }
  }

  @else if $breakpoint=='large' {
    @media only screen and (min-width: $breakpoint-large) {
      @content;
    }
  }

  @else if $breakpoint=='xlarge' {
    @media only screen and (min-width: $breakpoint-x-large) {
      @content;
    }
  }

  @else if $breakpoint=='xxlarge' {
    @media only screen and (min-width: $breakpoint-xx-large) {
      @content;
    }
  }
}

// ---------------------------------------------------------------------------------------------
// padding
// ---------------------------------------------------------------------------------------------
@mixin padding($padding-top, $padding-right, $padding-bottom, $padding-left) {
  padding-top: $padding-top;
  padding-right: $padding-right;
  padding-bottom: $padding-bottom;
  padding-left: $padding-left;
}

// ---------------------------------------------------------------------------------------------
// margin
// ---------------------------------------------------------------------------------------------
@mixin margin($margin-top, $margin-right, $margin-bottom, $margin-left) {
  margin-top: $margin-top;
  margin-right: $margin-right;
  margin-bottom: $margin-bottom;
  margin-left: $margin-left;
}

// ---------------------------------------------------------------------------------------------
// position absolute
// ---------------------------------------------------------------------------------------------
@mixin position-absolute($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// ---------------------------------------------------------------------------------------------
// box or rectangle
// help: input height and width or just width which makes height the same
// ---------------------------------------------------------------------------------------------
@mixin box($box-width, $box-height:$box-width) {
  width: $box-width;
  height: $box-height;
}

// ---------------------------------------------------------------------------------------------
// set border of element
// help: leave border-sides blank for all side the same
// ---------------------------------------------------------------------------------------------
@mixin border($border-width, $border-style, $border-color, $border-sides:'') {
  @if ($border-style !='') {
    @if ($border-sides=='') {
      border: $border-width $border-style $border-color;
    }

    @else {
      @each $border-side in $border-sides {
        @if ($border-side=='top'or $border-side=='right'or $border-side=='bottom'or $border-side=='left') {
          border-#{$border-side}: $border-width $border-style $border-color;
        }
      }
    }
  }
}

// ---------------------------------------------------------------------------------------------
// box shadow
// ---------------------------------------------------------------------------------------------
@mixin box-shadow {
  box-shadow: #d1d4d3 0 0 .5em 0;
}

// ---------------------------------------------------------------------------------------------
// border radius
// ---------------------------------------------------------------------------------------------
@mixin border-radius($top-left, $top-right, $bottom-right, $bottom-left) {
  border-top-left-radius: $top-left;
  border-top-right-radius: $top-right;
  border-bottom-right-radius: $bottom-right;
  border-bottom-left-radius: $bottom-left;
  background-clip: padding-box;
}

// ---------------------------------------------------------------------------------------------
// a link
// ---------------------------------------------------------------------------------------------
@mixin text-link($color, $text-decoration, $hover-color, $hover-text-decoration) {
  color: $color;
  text-decoration: $text-decoration;

  &:hover {
    color: $hover-color;
    text-decoration: $hover-text-decoration;
  }

  &:focus {
    background-color: $link-focus-color;
    color: $font-color;
    text-decoration: none;
    box-shadow: 0 4px 0 0 black;
  }

  &:focus-visible {
    outline: none;
  }
}

// ---------------------------------------------------------------------------------------------
// horizontal rule
// ---------------------------------------------------------------------------------------------
@mixin horizontal-rule {
  height: 0;
  border: none;
  border-top: 1px solid $grey-box-border;
}

// ---------------------------------------------------------------------------------------------
// chevrons
// ---------------------------------------------------------------------------------------------
@mixin arrow-right($color, $hover-color, $border-width) {
  border: solid $color;
  border-width: 0 $border-width $border-width 0;
  display: inline-block;
  padding: 4px;

  &:hover {
    border-color: $hover-color;
  }

  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

@mixin arrow-left($color, $hover-color, $border-width) {
  border: solid $color;
  border-width: 0 $border-width $border-width 0;
  display: inline-block;
  padding: 4px;

  &:hover {
    border-color: $hover-color;
  }

  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

@mixin arrow-up($color, $hover-color, $border-width) {
  border: solid $color;
  border-width: 0 $border-width $border-width 0;
  display: inline-block;
  padding: 4px;

  &:hover {
    border-color: $hover-color;
  }

  transform: scaleX(.8) rotate(-135deg);
  -webkit-transform: scaleX(.8) rotate(-135deg);
}

@mixin arrow-down($color, $hover-color, $border-width) {
  border: solid $color;
  border-width: 0 $border-width $border-width 0;
  display: inline-block;
  padding: 4px;

  &:hover {
    border-color: $hover-color;
  }

  transform: scaleX(.8) rotate(45deg);
  -webkit-transform: scaleX(.8) rotate(45deg);
}

// ---------------------------------------------------------------------------------------------
// truncate text
// ---------------------------------------------------------------------------------------------
@mixin truncate($width) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: $width;
}

// ---------------------------------------------------------------------------------------------
// modalConfirm data table container
// ---------------------------------------------------------------------------------------------
@mixin table-container {
  overflow-y: auto;
  position: absolute;
  top: 20px;
  bottom: 100px;
  left: 0;
  right: 0;

  @include screen-size(small) {
    bottom: 50px;
  }

  table {
    @include margin(0, 0, 0, 0);

    tbody {
      tr:last-child {
        border-bottom: none;
      }
    }
  }
}

// ---------------------------------------------------------------------------------------------
// text input
// ---------------------------------------------------------------------------------------------
@mixin textbox-border {
  @include border(1px, solid, $input-border-color);
}

@mixin textbox {
  @include textbox-border;
  @include padding(5px, 12px, 5px, 12px);
  @include font-size(16px);

  box-sizing: border-box;
  width: 100%;
  height: 42px;

  &:focus {
    box-shadow: 0 0 0 4px $brand-color-3;
  }

  &:focus-visible {
    outline: none;
  }

  &.w25 {
    width: 25%;
  }

  &.w50 {
    width: 50%;
  }

  &.w75 {
    width: 75%;
  }

  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
}

// ---------------------------------------------------------------------------------------------
// summary row
// ---------------------------------------------------------------------------------------------
@mixin summary-row {
  display: flex;
  flex-direction: column;

  @include screen-size(medium) {
    flex-direction: row;
  }

  @include margin(null, null, $gutter-small, null);
  @include padding(null, null, 10px, null);
  @include border(1px, solid, $grey-box-border, bottom);

  .label {
    font-weight: bold;
    width: 280px;

    @include margin(null, null, $gutter-small, null);

    @include screen-size(medium) {
      @include margin(null, null, 0, null);
    }

    &:empty {
      display: none;
    }
  }

  .action {
    padding-top: 20px;

    @include screen-size(medium) {
      margin-left: auto;
      padding-top: 0;
    }
  }

  &.block {
    @include margin(null, null, 0, null);
    @include padding($gutter-small, $gutter-small, $gutter-small, $gutter-small);
    @include border(1px, solid, $grey-box-border);

    background-color: $grey-box-background;
    flex-direction: row;

    .label {
      @include margin(null, null, $gutter-small, null);
    }
  }

  &.noBorder {
    border: none;

    @include margin(null, null, 10px, null);
  }

  @media print {
    flex-direction: row;
  }
}

// ---------------------------------------------------------------------------------------------
// details tag
// ---------------------------------------------------------------------------------------------
@mixin details {
  @include margin(null, null, $gutter-medium, null);

  .summary {
    @include padding(3px, 0, 3px, 18px);

    position: relative;
    display: inline-block;
    list-style-type: none;
    color: $link-color;
    text-decoration: underline;

    &::before {
      @include position-absolute(7px, null, null, -1px);
      @include arrow-right($link-color, $link-hover-color, 3px);

      content: '';
      clip-path: none !important;
    }

    &:hover {
      text-decoration: none;
    }

    &:focus {
      background-color: $link-focus-color;
      color: $font-color;
      text-decoration: none;
      box-shadow: 0 4px 0 0 black;

      &::before {
        border-color: black !important;
      }
    }

    &:focus-visible {
      outline: none;
    }
  }

  &[open] > .summary::before {
    @include arrow-down($link-color, $link-hover-color, 3px);

    top: 5px;
    left: 2px;
  }

  .content {
    @include margin(25px, null, null, null);

    p {
      @include margin(null, null, 20px, null);
    }
  }
}
