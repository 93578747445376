@import '~scss/public/imports';

@include screen-size(medium) {
  .phoneNumber,
  .vehicleRegistration,
  .authorisationNumber {
    width: 50%;

    @include padding(null, $gutter-small, $gutter-medium, null);
  }

  .data {
    display: flex;

    @include margin(null, null, $gutter-medium, null);

    .firstName,
    .lastName {
      width: 50%;
    }

    .firstName {
      @include padding(null, $gutter-small, null, null);
    }
  }
}
