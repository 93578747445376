@import '~scss/backOffice/imports';

.displayValue {
  @include margin(null, null, 30px, null);

  display: flex;
  flex-direction: column;

  @include screen-size(medium) {
    flex-direction: row;
  }

  .label {
    @include margin(null, null, 5px, null);

    min-width: 205px;
    font-weight: bold;

    @include screen-size(medium) {
      @include margin(null, null, 0, null);
    }
  }

  &.block {
    flex-direction: column;

    .label {
      @include margin(null, null, 5px, null);
    }
  }
}

.multiValues span {
  @include margin(null, null, 5px, null);

  display: block;
}
