@import '~scss/backOffice/imports';

/* create a custom checkbox */
.checkmark {
  @include border(1px, solid, $input-border-color);

  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: $border-radius;

  &::after {
    content: '';
    position: absolute;
    display: none;
  }
}

.checkbox {
  @include padding(1px, null, null, 30px);
  @include margin(null, null, 20px, null);

  display: block;
  position: relative;
  color: $input-font-color;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:focus ~ .checkmark {
      box-shadow: 0 0 0 4px $brand-color-3;
    }
  }

  .checkmark::after {
    left: 6px;
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:hover input ~ .checkmark {
    @include border(1px, solid, $input-border-color);

    background-color: #fafafa;
  }

  input:checked ~ .checkmark {
    @include border(1px, solid, $brand-color-1);

    background-color: white;
  }

  input:checked ~ .checkmark::after {
    display: block;
  }

  &.inline {
    @include margin(null, 25px, null, null);
  }
}

.disabled {
  cursor: not-allowed;
  opacity: .5;
}
