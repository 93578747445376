@import '~scss/backOffice/imports';

span.transferDates {
  margin-top: -10px;
}

.selectDates,
.crossBorder,
.actions {
  @include margin(null, null, 50px, null);

  display: flex;
  flex-direction: column;
}

.premises {
  @include margin(null, null, 20px, null);

  display: flex;
  flex-direction: column;
}

.selectDates {
  @include margin(null, null, 0, null);
}

.date {
  @include margin(null, 30px, 20px, null);

  width: 100%;
}

.cph {
  @include margin(null, 30px, null, null);

  width: 100%;
}

.reset {
  margin-left: auto;
}

.movementId,
.paperId {
  @include margin(null, null, 50px, null);

  width: 100%;
}

.error {
  border-color: $color-warning;
  color: $color-error;
  font-weight: bold;
}

@include screen-size(medium) {
  .selectDates,
  .premises,
  .actions {
    flex-direction: row;
  }

  .selectDates {
    @include margin(null, null, 20px, null);
  }

  .date,
  .cph {
    @include margin(null, 30px, 0, null);

    width: 300px;
  }

  .movementId,
  .paperId {
    width: 300px;
  }
}
