@import '~scss/public/imports';

.confirmation {
  @include padding(20px, 20px, 20px, 20px);
  @include border(4px, solid, transparent, left);
  @include margin(null, null, $gutter-small, null);
}

.pending {
  color: black;
  background-color: $color-warning-background;
  border-color: $color-warning;
}

.confirm {
  color: $color-success;
  background-color: $color-success-background;
  border-color: green;
}

.basic {
  color: $color-info;
  background-color: $color-info-background;
  border-color: $color-info;
}

.error {
  color: black;
  background-color: #fbecec;
  border-color: red;
}

.warning {
  color: black;
  background-color: #fff3cd;
  border-color: brown;
}

.errorWarning {
  color: black;
  background-color: #fbecec;
  border-color: brown;

  @include padding(15px, null, null, null);
}

.icon {
  @include font-size(20px);
  @include margin(null, 6px, null, null);
}

.title {
  @include font-size(16px);
  @include margin(null, null, $gutter-small, null);

  // display: flex;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;

  @include padding(15px, null, null, null);
}

.text {
  @include font-size(16px);
}

.status {
  max-width: 100%;
  margin-left: 10px;
}

.statusText {
  display: block;
  font-weight: bold;
  margin-top: $gutter-small;
}

.whatNow {
  @include padding(10px, null, null, null);
}

.parsedHtml {
  strong {
    font-weight: bold;
  }
}

.actionButtons {
  display: flex;

  .action {
    margin-right: 20px;
  }
}

.messages {
  @include padding(null, null, null, 20px);

  list-style: initial;

  li {
    @include padding(4px, null, 4px, null);
  }
}
