@import '~scss/backOffice/imports';

.totalAnimals {
  @include margin(null, null, 50px, null);
  @include padding(15px, 5px, 15px, 5px);
  @include border(1px, solid, #aaa, bottom);

  background-color: $grey-box-background;
  font-size: 24px;
  font-weight: bold;
}

.itemsTotal {
  @include margin(null, null, 30px, null);
  @include font-size(25px);

  display: flex;
  align-items: center;

  .icon {
    margin-right: 10px;
  }

  .label {
    @include margin(null, null, 1px, null);
  }
}

.notification {
  @include margin(null, 0, 15px, 0);
  @include border(4px, solid, $color-error, left);
  @include padding(15px, 10px, 15px, 10px);
  @include font-size(15px);

  border-radius: $border-radius;
  width: calc(100% - 20px);

  p {
    display: flex;
    align-items: center;
  }

  .error {
    @include margin(20px, null, 10px, null);

    ~ .error {
      @include margin(4px, null, 10px, null);
    }

    .notificationTitle {
      display: flex;
      align-items: center;
    }
  }

  .icon {
    @include font-size(20px);
    @include margin(null, 5px, null, null);
  }

  &.success {
    @include margin(null, null, 14px, null);

    background-color: $color-success-background;
    border-color: $color-success;
    color: $color-success;

    .notificationTitle {
      @include margin(null, null, 20px, null);
    }

    p {
      color: $color-success;
    }
  }

  &.warning {
    background-color: $color-warning-background;
    border-color: $color-warning;
    color: $color-warning;

    p {
      color: $color-warning;
    }
  }

  &.alert {
    background-color: $color-error-background;
    color: $color-error;

    p {
      color: $color-error;
    }
  }
}

.fixedActions {
  @include position-absolute(null, 0, 0,  205px);
  @include padding(25px, 20px, 25px, 20px);
  @include border(1px, solid, $grey-box-border, top);

  background-color: white;

  .right {
    margin-right: 40px;
  }
}
