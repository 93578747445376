@import '~scss/public/imports';

.totalAnimals {
  @include margin(null, null, 50px, null);
  @include padding(15px, 5px, 15px, 5px);
  @include border(1px, solid, #AAA, bottom);

  background-color: $grey-box-background;
  font-size: 24px;
  font-weight: bold;
}

.notification {
  @include margin(15px, 0, 30px, 0);
  @include border(4px, solid, $color-error, left);
  @include padding(15px, 10px, 15px, 10px);

  width: 100%;

  .error {
    @include margin(4px, null, 10px, null);

    &:first-of-type {
      @include margin(20px, null, 10px, null);
    }

    .notificationTitle {
      display: flex;
      align-items: center;

      + .error {
        @include margin(16px, null, null, null);
      }

      .icon {
        @include font-size(20px);
        @include margin(null, 5px, null, null);
      }
    }
  }

  &.success {
    background-color: $color-success-background;
    border-color: $color-success;
    color: $color-success;

    @include margin(null, null, 14px, null);

    p {
      color: $color-success;
    }
  }

  &.warning {
    background-color: $color-warning-background;
    border-color: $color-warning;
    color: $color-warning;

    p {
      color: $color-warning;
    }
  }

  &.alert {
    background-color: $color-error-background;
    color: $color-error;
    border-radius: 0;

    p {
      color: $color-error;
    }
  }
}

.cphAndDate {
  width: 100%;

  @include screen-size(small) {
    > div {
      width: 250px;
    }
  }
}

.datesSection {
  @include padding(15px, null, null, null);
  @include border(1px, solid, #aaa, top);
}

.hasAddresses {
  border: none;
}
