@import '~scss/public/imports';

.marginLeft {
  margin-left: 15px;
}

.link {
  @include padding(3px, null, 3px, null);

  color: $link-color;

  &:focus {
    background-color: $link-focus-color;
    color: $font-color;
    text-decoration: none;
    box-shadow: 0 4px 0 0 black;
  }

  &:focus-visible {
    outline: none;
  }
}

.backLink {
  @include margin(null, null, $gutter-medium, null);
  @include padding(3px, null, 3px, null);

  display: inline-block;
  color: $font-color;

  &:focus {
    background-color: $link-focus-color;
    color: $font-color;
    text-decoration: none;
    box-shadow: 0 4px 0 0 black;
  }

  &:focus-visible {
    outline: none;
  }
}

.icon {
  @include font-size(16px);
}

.btn {
  @include box(null, 38px);
  @include padding(null, 15px, null, 15px);

  display: inline-block;
  border: none;
  background-color: white;
  line-height: 40px;
  width: auto;
  text-decoration: none;

  &:disabled {
    opacity: 0.3;
    background-color: gray;

    &:hover {
      background-color: gray;
    }
  }

  .extraContent {
    @include margin(null, null, null, 5px);
  }
}

.primary {
  @extend .btn;

  color: $brand-color-1;
  background-color: $brand-color-3;

  &:hover {
    background-color: $brand-color-10;
  }
}

.secondary {
  @extend .btn;

  color: white;
  background-color: $brand-color-1;

  &:hover {
    background-color: $brand-color-6;
  }
}

.tertiary {
  @extend .btn;

  color: $brand-color-1;
  background-color: white;

  @include border(1px, solid, $brand-color-1);

  &:hover {
    background-color: #f8f8f8;
  }
}

.clear {
  @extend .btn;

  color: $brand-color-1;
  background-color: white;
  border-color: black;
  box-shadow: none;
}
