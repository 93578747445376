@import '~scss/public/imports';

.filters {
  p {
    @include margin(null, null, 10px, null);
  }

  .download {
    position: absolute;
    bottom: 10px;
    right: 0;
  }
}
