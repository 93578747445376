@import '~scss/backOffice/imports';

.logo {
  position: absolute;
  top: 10px;
  left: 65px;
  width: 114px;
  height: 30px;
  background-image: url('~images/svg/logo-livestock.svg');
  background-repeat: no-repeat;

  @include screen-size(small) {
    display: none;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 205px;
  right: 0;
  height: 50px;
  background-color: $brand-color-1;
  z-index: 99;

  @include screen-size(small) {
    background-color: $brand-color-1;
  }

  &.closed {
    left: 0;

    @include screen-size(medium) {
      left: 50px;
    }
  }
}

.userInfo {
  display: none;
}

@include screen-size(small) {
  .userInfo {
    background-color: $brand-color-1;
    color: white;
    display: block;

    span {
      display: inline-block;
    }

    .icon {
      font-size: 20px;

      @include margin(null, 5px, null, null);
    }

    &.open {
      display: block;
    }

    &.closed {
      display: none;
    }

    .option {
      @include text-link(white, none, $brand-color-3, none);
      @include text-link($brand-color-1, none, $brand-color-3, underline);
      @include margin(null, null, null, 5px);

      cursor: pointer;
      display: inline-flex;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .user {
    @include padding(15px, 15px, 14px, 15px);

    align-items: center;
    cursor: pointer;
    display: inline-flex;
    float: right;
    position: relative;
    z-index: 200;

    &:focus {
      background-color: $brand-color-3;
      color: black !important;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .userOptions {
    background-color: $brand-color-1;
    position: absolute;
    top: 50px;
    right: 10px;
    white-space: nowrap;

    > * {
      @include padding(15px, 15px, 15px, 15px);

      color: white !important;
      display: block !important;
      margin: 0 !important;

      &:hover {
        background-color: $brand-color-6;
        text-decoration: none !important;
      }

      &:focus {
        background-color: $brand-color-3;
        color: black !important;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }
}

@media print {
  .header {
    display: none;
  }
}
