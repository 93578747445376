@import '~scss/public/imports';

// COMMON STYLES
.flexRow{
  display: flex;
  flex-direction: row;
}
.flexCoulmn{
  display: flex;
  flex-direction: column;
}
.alignFlexEnd {
  align-items: flex-end;
}
.alignFlexCenter {
  align-items: center; 
}
.noMargin {
  margin: 0;
}
.noPadding {
  padding: 0;
}
.fontBold{
  font-weight: 700;
}

.mainContanier{
  @include padding(20px, 0, 20px, 0);
  .titleSection{
    @include margin(0, 0, 30px, 0);
    &.bottomBorder{
      @include padding(0, 0, 20px, 0);
      @include border(1px, solid, #b1b4b6, bottom);
    }
    .pageTitle{
      @include font-size(35px);    
      font-weight: 700;
      line-height: 1.2!important;
      @include margin(0, 0, 20px, 0);
    }
  }
  
  .bodySection{
    @include padding(0px, 0, 20px, 0);
    .summaryBlock {
      .summaryRow {
        @include summary-row;
        .label {
          width: 300px;
        }
      }
      &.topSpace {
        @include margin(40px, 0, 0, 0);
      }
    }

    .declarationBlock{
      @include margin(30px, 0, 0, 0);
      @include padding(20px, 0, 20px, 20px);
      border-left: 8px solid #d2d2d2;
      > div, p {
        @include margin(0, 0, 20px, 0);
      }
      .declartionContent{
        @include margin(0, 0, 30px, 0);
      }
    }
      
  }

  .buttonSection {
    @extend .flexRow;   
    @extend .alignFlexCenter;
    &.displayBtnRight {
      justify-content: flex-end;
      Button {
        @include margin(null, null, null, $gutter-small);
      }
    }
    &.displayBtnBoth {
      justify-content: space-between;
      :first-child{
        Button {
          @include margin(null, $gutter-small, null, null);
        }
      }
      :last-child{
        Button {
          @include margin(null, null, null, $gutter-small);
        }
      }
    }
    .buttonLink {
      @include font-size(16px);
      text-decoration: underline;
      cursor: pointer;
      color: #1d70b8;
    }
  }
  .sectionTitle {
    @include font-size(20px);
    font-weight: 700;
    line-height: 1.2!important;
    @include margin(0, 0, 20px, 0);
    &.noBottomSpace {
      @include margin(0, 0, 0px, 0);
    } 
  }
  .sectionTitleInfo{
    @include margin(0, 0, 20px, 0);   
  }
  .vetDeclarationBack {
    > a {
      margin-bottom: 0;
    }    
  }
}

.notificationSection{
  color: black;
  background-color: #fff3cd;
  padding: 20px;
  border-left: 4px solid #856404;
  margin-bottom: 15px;
  & i {
    font-size: 20px;
    margin-right: 6px;
  }
  .titleText{
    margin-bottom: 15px;
    font-weight: bold;
  }
}

//----- VET HOME ----//
.cphNumber {
  flex-grow: 1;
  @include margin(null, $gutter-small, null, null);
}

@include screen-size(medium) {
  .cphNumber {
    flex-grow: 0;
  }
}
.cphResultSection{
  @include padding(30px, 0, 0, 0);
  .cphValue{
    @include margin(15px, 0, 0, 0);
  }
}

//----- VET HOLDING ----//
.holdingInfoContanier{
  @include padding(0, 0, 0, 0);
  .holdingInfoBlock{
      > div {
          margin-bottom: 10px;
      }
      .fieldLabel{
          font-weight: 700;
      }
  }
}

.notificationsWarning {
  @include margin($gutter-small, null, $gutter-medium, null);

  display: flex;
  align-items: center;
}

.icon {
  @include font-size(25px);
  @include margin(null, 10px, null, null);
}

.text {
  @include font-size(18px);

  font-weight: bold;
}


@media print {
  .notificationsWarning {
    &.notForPrint {
      display: none;
    }
  }
  .buttonSection {
    &.notForPrint {
      display: none;
    }
  }
  .mainContanier{
    .titleSection{
      margin: 0 !important;
      &.bottomBorder{
        padding: 0 !important;
      }
      .pageTitle{
        font-size: 24px !important;
        margin: 0 !important;
        padding: 0 0 10px 0 !important;
      }
    }
    .bodySection{
      .summaryBlock {
        .summaryRow {          
          .label {
            width: 230px !important;
          }
        }
      }
      .declarationBlock{
        > div, p {
          margin: 0 0 10px 0 !important;
        }
        .declartionContent{
          margin: 0 0 20px 0 !important;
        }
      }     
    }

  }
}