@import '~scss/backOffice/imports';

.summaryRow {
  @include summary-row;
}

.status {
  border-bottom: 1px solid $grey-box-border;
}

.title {
  @include margin(null, null, 2px, null);
  @include padding(null, null, 10px, null);

  font-weight: bold;
}

.code {
  @include padding(10px, null, null, null);
  @include margin(15px, null, null, null);

  @include screen-size(small) {
    @include margin(0, null, null, null);
  }

  @include screen-size(medium) {
    flex-direction: row;
  }

  display: flex;
  flex-direction: column;
}

.noBottomBorder {
  border-bottom: none;
}

.marginBottom {
  @include margin(null, null, 25px, null);
}
