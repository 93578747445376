@import '~scss/backOffice/imports';

.pageTitle {
  @include margin(null, null, 20px, null);
}

.h1 {
  @include font-size(25px);

  display: flex;
  align-items: center;
}

.pageTitleOne {
  display: none;
  font-weight: normal;
}

.pageTitleTwo {
  font-family: $font-face-semi-bold;
}

.icon {
  height: 45px;

  @include margin(null, 8px, null, null);
}

.back {
  @include margin(null, 8px, null, null);
  @include font-size(30px);
}

.arrow {
  display: none;

  @include font-size(30px);
  @include margin(null, 5px, null, 5px);
}

@include screen-size(medium) {
  .arrow,
  .pageTitleOne {
    display: inline-block;
  }
}
