@import '~scss/public/imports';

.userCommentRow {
  @include summary-row;
}

.value {
  flex: 1;
  overflow-wrap: anywhere;
}
