@import '~scss/public/imports';

.content {
  height: 100%;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.input {
  text-align: left;
}

.output {
  position: relative;
  flex: 1 1 0;
}

.batchNumber,
.description {
  display: none;
}

.action {
  display: table-cell !important;

  &::before {
    display: none;
  }
}

.animalTotal {
  width: 160px;
}

@include screen-size(medium) {
  .batchNumber {
    display: table-cell;
  }
}

@include screen-size(large) {
  .description {
    display: table-cell;
  }
}

.tableContainer {
  @include table-container;
}
