@import '~scss/backOffice/imports';

.block {
  display: block;
}

.inline {
  display: flex;
  flex-direction: row;
}

.formGroup {
  @include form-group;
}

.textbox {
  @include input;

  &:disabled {
    background-color: #e9ecef;
  }
}

.half {
  width: 50%;
}

.quarter {
  width: 25%;
}

.fieldDescription {
  @include font-size(14px);
}
