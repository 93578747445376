@import '~scss/backOffice/imports';

.summaryRow {
  @include summary-row;
}

.showFCI {
  display: block;

  @include margin(null, null, 16px, null);

  color: $brand-color-1;
  text-decoration: underline;
  cursor: pointer;
}

.icon {
  @include margin(null, 5px, null, null);
}

.totalAnimals {
  @include margin(null, null, 16px, null);

  font-weight: bold;

  @include font-size(20px);
}

.title {
  display: block;
  font-weight: bold;

  @include margin(16px, null, 2px, null);
}

.fci {
  @include border(1px, solid, $grey-box-border, bottom);
  @include margin(null, null, 35px, null);
  @include padding(10px, null, 10px, null);
}

.fciSummary {
  padding: 15px 0 0 0 !important;
  border-left: none !important;
}
