@import '~scss/public/imports';

.radioButtons {
  display: flex;
  flex-direction: column;

  &.inline {
    flex-direction: column;

    &.bo {
      @include margin(null, null, 25px, null);
    }
  }
}

.otherHolding {
  max-height: 365px;
  overflow-y: auto;

  > div {
    @include padding(16px, 10px, 16px, 43px);

    position: relative;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid $grey-box-border;

    &:last-child {
      border-bottom: none;
    }
  }
}

@include screen-size(small) {
  .radioButtons {
    display: flex;
    flex-direction: column;

    &.inline {
      flex-direction: row;
    }
  }
}
