@import '~scss/public/imports';

.addBatchRow {
  @include margin(10px, 0, 10px, 0);
  @include padding(0, null, 20px, null);

  display: flex;
  flex-direction: column;
}

.batchNumber,
.startNumber,
.quantity {
  @include margin(null, $gutter-small, $gutter-small, null);
}

@include screen-size(medium) {
  .addBatchRow {
    @include padding(10px, null, 0, null);

    flex-direction: row;
    align-items: flex-end;
  }

  .batchNumber {
    @include margin(null, $gutter-small, 0, null);

    width: 240px;
  }

  .startNumber {
    @include margin(null, $gutter-small, 0, null);

    width: 130px;
  }

  .quantity {
    @include margin(null, $gutter-small, 0, null);

    width: 70px;
  }
}
