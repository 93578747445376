@import '~scss/public/imports';

.informationMessage {
  @include border(4px, solid, $color-info, left);
  @include padding(15px, 15px, 15px, 15px);
  @include margin(null, null, $gutter-medium, null);

  background-color: $color-info-background;
}

.title {
  @include margin(null, null, 15px, null);

  font-weight: bold;
  color: $color-info;
}

.content {
  color: $color-info;

  ul {
    @include margin(0, 0, 0, 0);

    li {
      @include margin(null, null, 10px, null);
      @include padding(null, null, null, 20px);

      position: relative;
    }

    li::before {
      @include position-absolute(6px, null, nulll, -2px);
      @include arrow-right($color-info, $color-info, 3px);

      content: '';
    }
  }
}
