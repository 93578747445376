@import '~scss/public/imports';

.radioButton {
  @include padding(3px, null, 3px, 28px);
  @include margin(null, null, 12px, null);

  display: block;
  position: relative;
  color: $font-color;

  label {
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include border(1px, solid, $input-border-color);

    &::after {
      content: '';
      position: absolute;
      display: none;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $brand-color-1;
    }
  }

  &.otherHolding {
    &.checked {
      @include border(1px, solid, $brand-color-3, bottom);

      background-color: $brand-color-3-o;

      &:last-child {
        border: none;
      }
    }

    .checkmark {
      top: 16px;
      left: 10px;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;

    &:focus + .checkmark {
      box-shadow: 0 0 0 4px $brand-color-3;
    }

    &:checked ~ .checkmark {
      @include border(1px, solid, $brand-color-1);

      background-color: white;

      &::after {
        display: block;
      }
    }
  }

  &.disabled {
    opacity: .5;

    label {
      cursor: not-allowed;
    }
  }

  &.inline {
    @include margin(null, 25px, $gutter-small, null);

    @include screen-size(small) {
      @include margin(null, 25px, 0, null);
    }
  }

  &:last-of-type {
    @include margin(null, null, 0, null);
  }

  &:hover input ~ .checkmark {
    background-color: white;

    @include border(1px, solid, $input-border-color);
  }
}
