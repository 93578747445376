@import '~scss/backOffice/imports';

.verifyCustomerSearch {
  display: flex;
  flex-direction: column;
}

@include screen-size(medium) {
  .verifyCustomerSearch {
    flex-direction: row;
  }

  .keeperDetails {
    width: 320px;
  }

  .holdingDetails {
    @include margin(null, 40px, null, null);

    width: 320px;
  }
}
