@import '~scss/backOffice/imports';

.sectionTitleBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  @include border(1px, solid, $grey-box-border);
  @include margin(null, null, 30px, null);

  background-color: $grey-box-background;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.03);
  border-radius: $border-radius;
}

.iconContainer {
  background-color: $brand-color-1;

  @include box(50px, 52px);

  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.icon {
  @include font-size(25px);

  color: white;
}

.title {
  @include font-size(18px);
  @include padding(10px, 0px, 6px,10px);
  width: calc(100% - 100px);
}

.status {
  @include margin(20px, null, null, null);
}

.statusWarningHint {
  @include margin(null, 5px, null, 5px);

  color: black;

  @media screen and (max-width: 1100px) {
    @include margin(5px, 5px, 5px, 5px);
  }
}

.statusErrorHint {
  @include margin(null, 5px, null, 5px);

  color: $color-error;

  @media screen and (max-width: 1100px) {
    @include margin(5px,5px, 5px, 5px);
  }
}

.CompletedWarning {
  @include border(4px, solid, red);
  @include padding(4px, 4px, 4px, 4px);
  @include margin(null, 5px, null, 5px);

  @media screen and (max-width: 1100px) {
    @include margin(10px, 5px, 5px, 5px);
  }

  background-color: $color-warning-background;
  color: black;
  font-size: 16px;
  font-weight: bolder;
}

.CompletedError {
  @include border(4px, solid, red);
  @include padding(4px, 4px, 4px, 4px);
  @include margin(null, 5px, null, 5px);

  @media screen and (max-width: 1100px) {
    @include margin(5px, 5px, 5px, 5px);
  }

  background-color: $color-error-background;
  color: $color-error;
  font-size: 16px;
  font-weight: bolder;
}
