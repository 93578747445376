@import '~scss/public/imports';

.durationInput {
  display: flex;
}

.days,
.hours,
.minutes {
  width: 75px;
}

.days {
  @include margin(null, 10px, null, null);
}

.hours {
  @include margin(null, 10px, null, 10px);
}

.minutes {
  @include margin(null, null, null, 10px);
}

.timePicker {
  width: 20%;
}

.timePickerInput {
  @include textbox-border;
  @include box(115px, 40px);
  @include padding(5px, 5px, 5px, 5px);

  border-radius: 0;
  font-size: $font-size-base;
  color: $font-color;
  cursor: pointer;

  &:focus {
    box-shadow: inset 0 0 0 2px;
  }

  &:focus-visible {
    outline: 2px solid #ccc;
  }
}
