@import '~scss/public/imports';

.content {
  height: 100%;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.input {
  text-align: left;
}

.output {
  position: relative;
  flex: 1 1 0;
}

.breedId,
.genderName,
.dob,
.age {
  display: none;
}

.action {
  display: table-cell !important;

  &::before {
    display: none;
  }
}

.tagNumber {
  width: 200px;
}

@include screen-size(small) {
  .breedId {
    display: table-cell;
    width: 210px;
  }
}

@include screen-size(medium) {
  .genderName {
    display: table-cell;
  }
}

@include screen-size(large) {
  .dob,
  .age {
    display: table-cell;
  }
}

.tableContainer {
  @include table-container;
}
