@import '~scss/backOffice/imports';

.fileUpload {
  @include padding(null, null, 30px, null);

  display: flex;
  flex-direction: column;
}

.fileBrowser {
  display: flex;
  flex-direction: column;

  @include margin(5px, null, null, null);
}

.browseWrap {
  @include margin(null, null, 5px, null);

  background-color: white;
  border: 1px solid $input-border-color;
  width: 100%;
}

.upload {
  margin-left: 16px;
}

@include screen-size(medium) {
  .fileBrowser {
    flex-direction: row;
  }

  .browseWrap {
    width: 350px;
  }
}
